import React, { useState, useEffect } from 'react';
import { Icon } from '@rsuite/icons';
import { Input,InputGroup, Button, CustomProvider } from 'rsuite';
import { Container, Nav, Navbar } from 'rsuite';
import { Slider, Whisper, Tooltip, FlexboxGrid } from 'rsuite';
import { Drawer, IconButton, Toggle } from 'rsuite';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import MenuIcon from '@rsuite/icons/Menu';
import HistoryIcon from '@rsuite/icons/History';
import PeoplesMapIcon from '@rsuite/icons/PeoplesMap';
import 'rsuite/dist/rsuite.min.css';
import * as faThumbsUp from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import * as faThumbsDown from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import * as faUser from '@fortawesome/free-solid-svg-icons/faUser';
import * as faHeart from '@fortawesome/free-solid-svg-icons/faHeart';
import { Modal } from 'rsuite';

function App() {
  
  const eventName = "AWS-GENERIC-2024"
  
  // fa integration
  const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  
  return (
      <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
        <path d={svgPathData}></path>
      </svg>
    );
  };
  
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(window.innerWidth > 768 ? true : false);
  const handleClose = () => setOpen(false);
  
  const [checked, setChecked] = useState(false);
  const [inputUser, setInputUser] = React.useState("");
  const [usernameTaken, setUsernameTaken] = React.useState(false);
  
  const [intervalId, setIntervalId] = useState(null);
  
  const [promptInput, setPromptInput] = useState({
      "cfg_scale": 8.0,
      "seed": 20202,
      "text_prompts": "",
      "negative_prompts": "",
      "username": null
  });
  const [imageUrl, setImageUrl] = useState('');
  const [filename, setFilename] = useState('');
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thumbLoading, setThumbLoading] = useState(false);
  const [heartDisabled, setHeartDisabled] = useState(true);
  const [usernameLoading, setUsernameLoading] = useState(false);
  const [imageUsername, setImageUsername] = useState('');
  
  
  useEffect(() => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      let [key, value] = cookie.split('=');
      if (key.trim() === 'username' && value !== null) {
        updatePromptInput("username", value)
        break;
      }
    }
  }, []);
  
  useEffect(() => {
    clearInterval(intervalId);
    setImageList([]);
    setImageUrl("")
    setHeartDisabled(true)
    getImages();
    if (checked){
      const id = setInterval(() => {
          getImages();
        }, 10000);
      setIntervalId(id);
    }
  }, [checked])
  
  useEffect(() => {
    setOpen(false)
    if (promptInput.username && promptInput.username !== "null"){
      getImages();
      if (checked == 1){
        const id = setInterval(() => {
          getImages();
        }, 10000);
        setIntervalId(id);
      }
    }
    else{
      setOpen(true);
    }
    return () => clearInterval(intervalId);
  }, [promptInput.username]);
  
  const updatePromptInput = async (field, value) => {
    setPromptInput({
      ...promptInput,
      [field]: value 
    });
  }
  
  const generateImage = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Construct the request body with form data
    var input = promptInput
    input["eventname"] = eventName
    const requestBody = JSON.stringify(input);
  
    try {
      const response = await fetch('https://api.imaginethis.cloud/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/html'
        },
        body: requestBody,
      });
          
      if (response.ok) {
        // Handle success here
        console.log('Image successfully generated.');
        const jsonBody = await response.json();
        setImageUrl(jsonBody.imgUrl);
        setFilename(jsonBody.filename)
        setImageUsername(jsonBody.username)
        getImages();
        setLoading(false);
      } else {
        // Handle error here
        setLoading(false);
        console.error('Image generation failed');
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }
  };
  
  const getImages = async () => {
    setThumbLoading(true);
    try {
      if (!checked && promptInput.username){
        const requestBody = JSON.stringify({ "username": promptInput.username, "eventname": eventName});
        const response = await fetch('https://api.imaginethis.cloud/getImagesByUsername', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/html'
          },
          body: requestBody,
        });
        if (response.ok) {
          // Handle success here
          setThumbLoading(false);
          console.log('Images by user loaded.');
          const jsonBody = await response.json();
          setImageList(jsonBody.map(image => {
            const obj = {
              "key": image.key,
              "thumbnailUrl": "https://static.imaginethis.cloud/"+eventName+"/"+promptInput.username+"/thumbnail/"+image.key,
              "generatedUrl": "https://static.imaginethis.cloud/"+eventName+"/"+promptInput.username+"/generated/"+image.key,
              "cfg_scale": image.cfg_scale,
              "seed": image.seed,
              "text_prompts": image.text_prompts,
              "negative_prompts": image.negative_prompts,
              "username": image.username,
              "heart": image.heart
            }
            return obj; 
          }));
        } else {
          // Handle error here
          console.error('Images by user loading failed');
        }
      }
      if (checked && eventName){
        const requestBody = JSON.stringify({ "eventname": eventName});
        const response = await fetch('https://api.imaginethis.cloud/getImagesByEvent', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/html'
          },
          body: requestBody,
        });
         if (response.ok) {
            // Handle success here
            setThumbLoading(false);
            console.log('Images by event loaded.');
            const jsonBody = await response.json();
            setImageList(jsonBody.map(image => {
              const obj = {
              "key": image.key,
              "thumbnailUrl": "https://static.imaginethis.cloud/"+eventName+"/"+image.username+"/thumbnail/"+image.key,
              "generatedUrl": "https://static.imaginethis.cloud/"+eventName+"/"+image.username+"/generated/"+image.key,
              "cfg_scale": image.cfg_scale,
              "seed": image.seed,
              "text_prompts": image.text_prompts,
              "negative_prompts": image.negative_prompts,
              "username": image.username,
              "heart": image.heart
            }
              return obj; 
            }));
          } else {
            // Handle error here
            console.error('Images by event loading failed');
          }
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred:', error);
    }
  }
  
  const handleDrawer = () => {
    setDrawerOpen(true);
  }
  
  const handleClick = (image) => {
    setDrawerOpen(false)
    setImageUrl(image.generatedUrl);
    if (!checked && !image.heart && image.generatedUrl !== null && image.generatedUrl !== ""){
      setHeartDisabled(false)
    }
    else{
      setHeartDisabled(true)
    }
    const splitStr = image.generatedUrl.split("/")
    setFilename(splitStr[splitStr.length - 1])
    setImageUsername(image.username)
    setPromptInput({
      "cfg_scale": image.cfg_scale,
      "seed": image.seed,
      "text_prompts": image.text_prompts,
      "negative_prompts": image.negative_prompts,
      "username": promptInput.username
    });
  }
  
  const handleUsername = async (event) => {
    event.preventDefault()
    setUsernameLoading(true);
    setUsernameTaken(false);
    if (inputUser && inputUser !== "null"){
      try {
        const requestBody = JSON.stringify({
          "eventname": eventName,
          "username": inputUser
        });
        console.log('Username OK!');
        updatePromptInput("username", inputUser)
        document.cookie = `username=${inputUser}`;
        setUsernameLoading(false);
        // const response = await fetch('https://api.imaginethis.cloud/checkUsername', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'text/html'
        //   },
        //   body: requestBody,
        // });
            
        // if (response.ok) {
        //   // Handle success here
        //   console.log('Username OK!');
        //   updatePromptInput("username", inputUser)
        //   document.cookie = `username=${inputUser}`;
        //   setUsernameLoading(false);
        // } else {
        //   // Handle error here
        //   setUsernameTaken(true);
        //   setUsernameLoading(false);
        //   console.error('Username not available!');
        // }
      } catch (error) {
        setUsernameLoading(false);
        console.error('An error occurred:', error);
      }
    }
    else{
      setUsernameLoading(false);
    }
  }
  
  const handleImage = async (event) => {
    event.preventDefault()
    setHeartDisabled(true);
    try {
      const requestBody = JSON.stringify({
        "imageUrl": imageUrl,
        "username": promptInput.username
      });
      const response = await fetch('https://api.imaginethis.cloud/updateImage', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/html'
        },
        body: requestBody,
      });
          
      if (response.ok) {
        getImages();
        console.log('Successfully added heart for '+imageUrl);
      } else {
        setHeartDisabled(false);
        console.error('Failed to add heart for '+imageUrl);
      }
    } catch (error) {
      setHeartDisabled(false);
      console.error('An error occurred:', error);
    }
  }
  
  const handleNav = (checked) => {
    console.log(checked)
  };
  
  return (
    <CustomProvider theme="light">
    <Modal backdrop="static" keyboard={false} open={open} onClose={handleClose}>
      <Modal.Body>
        <b>Welcome!</b><br/><br/>
        Unlock your creativity with our new AI-powered image generation app! Just type in a text prompt and watch as 
        the advanced AI create unique images for you.<br/><br/>
        First, please set a <b>username</b> so that we can match your generated images to your username.<br/><br/>
        <InputGroup className={ usernameTaken ?"inputGroup error":"inputGroup"}>
          <InputGroup.Addon><Icon as={FaSvgIcon} faIcon={faUser} /></InputGroup.Addon>
            <Whisper trigger={ usernameTaken ? "active" : "" } speaker={<Tooltip>Username is already taken.</Tooltip>} placement="bottom">
              <Input placeholder="Enter username"
                value={inputUser}
                onChange={(e) => setInputUser(e.replace(/\s/g, ''))}
              />
            </Whisper>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUsername} className="blue" disabled={usernameLoading} appearance="primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
      <Container>
      <IconButton className="drawerMenu" icon={<MenuIcon />} onClick={handleDrawer} />
      <Drawer
        backdrop={true} 
        open={drawerOpen} 
        onClose={() => setDrawerOpen(false)}
        placement="left"
        size="xs"
        >
        { false ? 
        <Drawer.Header>
            <div className="nav-header">
              <img className="logo" src="/img/aws-logo.png" alt="logo"/>
            </div>
        </Drawer.Header>
        : ""}
        <Drawer.Body>
          <div className="navTitle">
            <Toggle size="lg" checkedChildren="All Images" unCheckedChildren="Your generated images" checked={checked} onChange={setChecked} />
            <br/>{thumbLoading && <img alt="generated" className="spinner" src="img/spinner.gif" style={{maxWidth: "20px"}}/>}
          </div>
          {imageList.length > 0 ? imageList.map(image => (
            <div className="imageContainer">
            <img 
              key={image.key} src={image.thumbnailUrl}
              alt=" " 
              data-cfg={image.cfg_scale}
              data-seed={image.seed}
              data-text-prompts={image.text_prompts}
              data-negative-prompts={image.negative_prompts}
              data-username={image.username}
              data-heart={image.heart}
              className={imageUrl === image.generatedUrl ? "imageThumbnail selected" : "imageThumbnail"}
              onClick={() => handleClick(image)}
              onError="this.onerror=null;"
            />
            {image.heart ? <Icon className="heart" as={FaSvgIcon} color="red" faIcon={faHeart} />: ""}
            </div>
          ))
            : <span className="imageThumbnailEmpty">No images found.</span>
          }
        </Drawer.Body>
      </Drawer>
        <Container>
          <div className="App">
          <img src="/img/aws-logo.png" alt="logo" style={{ maxHeight: 50 }}/>
          <h3>AI Image Generator - Text To Image</h3>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={16}>
              <label>Prompt: </label>
              <InputGroup className="inputGroup">
                <InputGroup.Addon><Icon as={FaSvgIcon} faIcon={faThumbsUp} /></InputGroup.Addon>
                  <textarea placeholder="Enter prompts"
                    className="promptInput"
                    value={promptInput.text_prompts}
                    onChange = {(e) => updatePromptInput("text_prompts", e.target.value)}
                  />
                </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={16}>
              <label>Negative prompt (optional): </label>
               <InputGroup className="inputGroup">
                <InputGroup.Addon><Icon as={FaSvgIcon} faIcon={faThumbsDown} /></InputGroup.Addon>
                  <Input placeholder="Enter negative prompts" 
                    value={promptInput.negative_prompts}
                    onChange = {(e) => updatePromptInput("negative_prompts", e)}
                  />
                </InputGroup>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={8}>
              <label>Seed: </label>{promptInput.seed}
              <Slider
                progress
                defaultValue={promptInput.seed}
                value={promptInput.seed}
                max={30000}
                onChange= {(e) => updatePromptInput("seed", e)}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              <label>CFG Scale: </label>{promptInput.cfg_scale}
               <Slider
                progress
                defaultValue={promptInput.cfg_scale}
                value={promptInput.cfg_scale}
                max={10}
                step={0.1}
                onChange= {(e) => updatePromptInput("cfg_scale", e)}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
            <br/><br/>
            <Button disabled={loading} appearance="primary" className="blue" size="lg" onClick={generateImage}>{loading ? 'Loading...' : 'Generate!'}</Button>
            <Button disabled={heartDisabled} appearance="primary" color="red" size="lg" onClick={handleImage}><Icon as={FaSvgIcon} faIcon={faHeart} /></Button>
            <div className="image-container">
              {loading && <img alt="generated" className="spinner" src="img/spinner.gif"/>}
              {imageUrl && (<span><div className="filename">{imageUsername} | {filename}<br/></div><img className="generatedImage" src={imageUrl} alt="Generated" /></span>)}
            </div>
          </div>
        </Container>
      </Container>
    </CustomProvider>
  );
  
}

export default App;